import React from "react";


//Constants
import { ROUTES } from "./RouteConstants";

//Components
import LandingPageContainer from "../Components/LandingPage/LandingPageContainer";
import CreateMeetingContainer from "../Components/MeetingPreview/MeetingPreviewContainer";
import InCallContainer from "../Components/InCallComponent/InCallContainer";
import RequestDemoContainer from "../Sales-Flow/RequestDemo/RequestDemoContainer";
import FeedbackPageContainer from "../Sales-Flow/FeedbackPage/FeedbackPageContainer";



const routes = [
    {
        path: ROUTES.LANDING_PAGE,
        exact: true,
        Component: () => (
            < LandingPageContainer />
        ),
    },
    {
        path: ROUTES.CREATE_MEETING,
        exact: true,
        Component: () => (
            < CreateMeetingContainer />
        ),
    },
    {
        path: ROUTES.IN_CALL,
        exact: true,
        Component: () => (
            < InCallContainer />
        ),
    },


    //sales-demo
    {
        path: ROUTES.ROOT_PATH,
        exact: true,
        Component: () => (
            < RequestDemoContainer />
        ),
    },
    {
        path: ROUTES.FEEDBACK,
        exact: true,
        Component: () => (
            < FeedbackPageContainer />
        ),
    },

]

export default routes;