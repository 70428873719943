import { FunctionComponent, useEffect } from "react";
import { Switch } from "antd";
import { useSelector } from "react-redux";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import { ReactComponent as Transcript } from "../../Resources/Images/union3.svg"
import { ReactComponent as Participants } from "../../Resources/Images/union4.svg"
import { ReactComponent as RecordIcon } from "../../Resources/Images/union2.svg"
import { ReactComponent as ScreenShare } from "../../Resources/Images/subtraction-40.svg"
import { ReactComponent as Layout } from "../../Resources/Images/subtraction-44.svg"
import { ReactComponent as Vector11 } from "../../Resources/Images/vector11.svg"
import crossIcon from "../../Resources/Images/icadd2@2x.png"

//Redux
import {
  onChangeLayout,
  onParticipantPanelToggle,
  onScreenSharing,
  onTranscriptToggle
} from "../../Redux/Actions/ToolbarActions";

//Styles
import "./MobileActionBar.scss";

export type MobileActionBarType = {
  className?: string;
  onClose?: () => void;
};

const MobileActionBar: FunctionComponent<MobileActionBarType> = ({
  className = "",
  onClose,
}) => {

  const {
    screenShareStarted,
    transcriptOn,
  } = useSelector((state: any) => state.ToolbarReducer);

  // State to manage which components are visible
  const { configurations } = useSelector((state: any) => state.LoginReducer);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);


  return (
    <div className={`mobileactionbar ${className}`} data-animate-on-scroll>
      <b className="create-an-account1">{getTranslation("moreOption")}</b>
      {/* <div className="frame-div">
        <div className="bgblur-group">
          <div className="bgblur1">
            <Vector11 className="vector-icon25" />
          </div>
          <div className="already-have-an6">{getTranslation("bgBlur")}</div>
        </div>
        <Switch className="switch23 custom-switch" style={{ width: 26 }} />
      </div> */}
      <div className="mobileactionbar-child" />
      <div className="callactionsmic-parent">
        {/* <button className="callactionsmic8">
          <div className="iconrecord1">
            <img className="union-icon9" alt="" src={RecordIcon} />
          </div>
          <div className="mic11">{getTranslation("record")}</div>
        </button> */}
        {/* <button className="callactionsmic8" onClick={() => onScreenSharing(screenShareStarted)}>
          <div className="iconrecord1">
            <img
              className="subtraction-40-icon1"
              alt=""
              src={ScreenShare}
            />
          </div>
          <div className="mic11">{getTranslation("shareScreen")}</div>
        </button> */}
        {
          "transcript" in configurations && <button className="callactionsmic8" onClick={onTranscriptToggle}>
            <div className="iconrecord1">
              <Transcript className="union-icon10" />
            </div>
            <div className="mic11">{getTranslation("transcript")}</div>
          </button>
        }
        <button className="callactionsmic8" onClick={onParticipantPanelToggle}>
          <div className="iconrecord1">
            <Participants className="union-icon11" />
          </div>
          <div className="mic11">{getTranslation("participants")}</div>
        </button>
        {/* <button className="callactionsmic8" onClick={onChangeLayout}>
          <div className="iconrecord1">
            <img
              className="subtraction-44-icon1"
              alt=""
              src={Layout}
            />
          </div>
          <div className="mic11">{getTranslation("layout")}</div>
        </button> */}
      </div>
      <div className="mobileactionbar-child" />
      <button className="buttonsignin-parent" onClick={onClose}>
        <button className="buttonsignin15">
          <img className="icadd2-icon9" alt="" src={crossIcon} />
        </button>
        <div className="already-have-an7">{getTranslation("close")}</div>
      </button>
    </div>
  );
};

export default MobileActionBar;
