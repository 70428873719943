import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Participant, Track } from "proconf-web-sdk";

//Custom Component
import InCallHeader from "../../Common Components/InCallHeader/InCallHeader";
import InCallBodyContainer from "../../Common Components/InCallBodyContainer/InCallBodyContainer";
import ToolActionBar from "../../Common Components/ToolBar/ToolActionBar";

//store
import { store } from "../../Redux/store";

//Styles
import "./InCallComponent.scss";

interface InCallProps {
	meetingName: string;
	setInvitePopup: Function;
}
const InCall: React.FC<InCallProps> = ({ meetingName, setInvitePopup }) => {
	console.log('meetingId: ', store.getState().SalesReducer);

	const participants = useSelector(
		(state: any) => state.ParticipantReducer?.participants
	);

	const localParticipant = useSelector(
		(state: any) => state.ParticipantReducer?.localParticipant
	);
	const remoteParticipants = useSelector(
		(state: any) => state.ParticipantReducer?.participants
	).filter((p: any) => p?.sid !== localParticipant?.sid);

	// Array of screen sharing participants (needed for multiple screen share)
	const ssParticipants = useSelector(
		(state: any) => state.ParticipantReducer?.participants
	).filter((p: any) => p.videoTracks?.filter((v: any) => v?.trackName.includes("screen")));

	const isScreenShared = useSelector(
		(state: any) => state.ProConfReducer?.isRemoteSSOn
	);

	const [screenSharingParticipant, setScreenSharingParticipant] = useState(
		{} as Participant
	);

	useMemo(() => {
		console.log("Participant list updated...inside usememo");
		// Attaching local participant video again as UI is updated
		if (localParticipant) {
			let tracks = Array.from(
				localParticipant?.videoTracks?.values()
			) as Track[];
			if (tracks[0]?.isEnabled && tracks[0]?.getVideoType() === "camera") {
				setTimeout(() => {
					tracks[0].attach(
						document.getElementById("local-video") as HTMLVideoElement
					);
				}, 1500);
			}
		}
		remoteParticipants &&
			remoteParticipants?.map((p: Participant) => {
				let tracks = Array.from(p.videoTracks.values()) as Track[];
				// if (tracks?.length > 1) {
				let isDesktopTrack = false;
				tracks?.map((t: Track) => {
					if ( t?.isEnabled && t?.getVideoType() === "desktop") {
						setScreenSharingParticipant(p);
						store.dispatch({
							type: "SET_REMOTE_SS",
							payload: true,
						});
						t?.attach(
							document.getElementById("screen-video") as HTMLVideoElement
						);
						isDesktopTrack = true;
					} else if (t?.isEnabled && t?.getVideoType() === "camera") {
						// Attach remote tracks again as UI gets updated
						setTimeout(() => {
							console.log("Attempt attach after subscribed....");
							t?.attach(
								document.getElementById(
									"remote-video-" + p.sid
								) as HTMLVideoElement
							);
						}, 3500);
					}
				});
			});
	}, [isScreenShared, participants?.length]);

	useEffect(() => {
		setTimeout(() => {
			//if (isScreenShared === true) {
			if (localParticipant) {
				let tracks = Array.from(
					localParticipant?.videoTracks?.values()
				) as Track[];
				if (tracks[0]?.isEnabled && tracks[0]?.getVideoType() === "camera") {
					tracks[0]?.attach(
						document.getElementById("local-video") as HTMLVideoElement
					);
				}
			}
			remoteParticipants?.map((rp: Participant) => {
				let tracks = Array.from(rp.videoTracks.values()) as Track[];
				if (tracks[0]?.isEnabled && tracks[0]?.getVideoType() === "camera") {
					tracks[0].attach(
						document.getElementById(
							"remote-video-" + rp?.sid
						) as HTMLVideoElement
					);
				}
			});
			// }
		}, 2000);
	}, [isScreenShared]);

	return (
		<div className="in-call-with-toolbar">

			<InCallHeader meetingName={meetingName}></InCallHeader>

			<InCallBodyContainer screenSharingParticipant={screenSharingParticipant} setInvitePopup={setInvitePopup} />

			<ToolActionBar />

		</div>
	);
};

export default InCall;
