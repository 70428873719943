import { FunctionComponent, RefObject } from "react";
import Avatar from "react-avatar";

//Store
import { store } from "../../Redux/store";

//Styles
import "./ProfileMenu.scss";

interface ProfileMenuType {
    className?: string;
    buttonRef: RefObject<HTMLButtonElement>;
    openLogoutPopup?: () => void;
};

const ProfileMenu: FunctionComponent<ProfileMenuType> = ({ className = "", buttonRef, openLogoutPopup }) => {

    const userName = store.getState()?.LoginReducer.userName;
    return (
        <button
            className={className}
            ref={buttonRef}
            onClick={openLogoutPopup}
        >
            <Avatar name={userName} round={true} textSizeRatio={3} size={'40'} />
        </button>
    );
};

export default ProfileMenu;
