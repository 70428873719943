import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

//Components
import StartMeetingForm from "./StartMeeting";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Action
import { fetchTokenizeURL, setRoomId, updateTimeStamp, updateVisitedCount } from "../Redux/Actions/SalesAction";
import { callJoinAsGuestnAPI } from "../../Redux/Actions/LoginAction";
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";

//Utility
import { getLastPartOfURL } from "../../Utility/Utils";

//Constants
import { API_STATUS } from "../../Constants/AppConstants";
import { ROUTES } from "../../Routes/RouteConstants";

//Services
import proConfService from "../../Services/ProConfService";
import { store } from "../../Redux/store";
import { setDataIntoReduxStore } from "../../Redux/Actions/StoreAction";
import { SET_PROFILE_NAME } from "../../Redux/ReduxConstants";


const StartMeetingContainer = ({ }) => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isParticipant, setIsParticipant] = useState(false);
    const [pid, setParticipantId] = useState("");
    const [roomName, setRoomName] = useState("");

    const roomId = getLastPartOfURL();
    console.log('roomId: ', roomId);
    setRoomId(roomId);

    const location = useLocation();
    const path = location.pathname.split('/').pop(); // Extracts 'meetingId' from the URL
    console.log('meetingId. path: ', path);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        updateVisitedCount(roomId)

        getParams()

    }, [])

    const getParams = () => {
        const pid = searchParams.get('pid') as string;
        const rn = searchParams.get('rn') as string;

        setParticipantId(pid)
        setRoomName(rn)


        console.log('guestflow rn: meetingId', path, pid, rn);

        if (path && pid && rn) {

            store.dispatch({ type: "SET_MEETING_ID", payload: path })
            store.dispatch({ type: "SET_PARTICIPANT_ID", payload: pid })
            store.dispatch({ type: "SET_ROOM_NAME", payload: rn })
            console.log('meetingId: getParams', store.getState().SalesReducer);

            setIsParticipant(true)
        }

    }

    const onStartMeeting = async (userName: string, roomName: string) => {
        setIsLoading(true)
        store.dispatch({ type: "SET_ROOM_NAME", payload: roomName });
        let response = await fetchTokenizeURL(userName, roomName, roomId)

        if (response?.status === API_STATUS.SUCCESS) {

            await proConfService.initProConf();

            navigate(ROUTES.CREATE_MEETING);
        }
        else {
            setErrorNotification(response?.response?.data?.error?.message)
        }
        setIsLoading(false)
    }

    const onJoinMeeting = async (userName: string) => {

        setIsLoading(true)

        store.dispatch({ type: "SET_PARTICIPANT_NAME", payload: userName });
        store.dispatch({ type: "SET_ROOM_NAME", payload: roomName });

        let response = await callJoinAsGuestnAPI(userName, roomName)

        console.log('userName, store.getState()?.RoomReducer?.roomName: ', userName, roomName, pid);

        await updateTimeStamp("joining");

        if (response?.status === API_STATUS.SUCCESS) {

            await proConfService.initProConf();
            setDataIntoReduxStore(SET_PROFILE_NAME, userName)

            navigate(ROUTES.CREATE_MEETING);
        }
        else {
            setErrorNotification(response?.response?.data?.error?.message)
        }

        setIsLoading(false)

    }

    const getUserDetails = () => {
        return ""
    }

    return (

        <>
            {isLoading && <LoadingSpinner />}

            <StartMeetingForm
                onStartMeeting={(
                    userName: string,
                    roomName: string
                ) =>
                    onStartMeeting(
                        userName,
                        roomName
                    )
                }
                onJoinMeeting={(userName: string) =>
                    onJoinMeeting(userName
                    )
                }
                isParticipant={isParticipant}
                getUserDetails={getUserDetails}
            />
        </>

    )
}

export default StartMeetingContainer;