
import { Room } from 'proconf-web-sdk';

//Service
import proConfService from '../../Services/ProConfService';

//Store
import { store } from '../store';

//Constants
import { SET_ROOM_NAME } from '../ReduxConstants';

export async function getMeetingList(): Promise<any[]> {
    const meetingsList = await proConfService.getMeetingList()
    return meetingsList;
}

export async function viewCallSummary(uniqueId: string): Promise<string> {
    let summary = await proConfService.viewCallSummary(uniqueId)
    return summary;
}

export async function createMeeting(roomName: string, username: string): Promise<Room | null> {
    let roomresult = await proConfService.startMeeting(roomName, username) as Room;
    store.dispatch({ type: SET_ROOM_NAME, payload: roomName });
    return roomresult;
}

export async function joinMeeting(roomname: string, username: string): Promise<Room | null | undefined> {
    let roomresult = await proConfService.joinMeeting(roomname, username);
    store.dispatch({ type: SET_ROOM_NAME, payload: roomname });
    return roomresult;
}

export async function getCatchup(roomname: string): Promise<string | null | undefined> {
  let catchup = await proConfService.getCallCatchUp(roomname)
  return catchup;
}

export async function getCallStatus(roomname: string): Promise<boolean> {
    let status = await proConfService.getCallStatus(roomname);
    return status;
}

