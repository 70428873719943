import { ParticipantActionTypes } from "./../ActionTypes/ParticipantActionTypes";
import { ParticipantState } from "../StateTypes/StateTypes";
import {
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  SET_LOCAL_PARTICIPANT,
  CLEAR_PARTICIPANTS
} from "../ReduxConstants";

// Define the initial state for the ProConf reducer
const initialState: ParticipantState = {
  localParticipant: null,
  participants: [],
};

// Reducer function to handle state changes based on actions
const ParticipantReducer = (
  state = initialState,
  action: ParticipantActionTypes
): ParticipantState => {

  switch (action.type) {
    case ADD_PARTICIPANT:
      return {
        ...state,
        participants: [...state.participants, action.payload],
      };
    case REMOVE_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.filter((p) => p?.sid !== action.payload),
      };
    case UPDATE_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.map((p) =>
          p.sid === action.payload.sid ? { ...p, ...action.payload } : p
        ),
      };
    case SET_LOCAL_PARTICIPANT:
      return { ...state, localParticipant: action.payload };
    case CLEAR_PARTICIPANTS:
      return {
        localParticipant: null,
        participants: [],
      }
    default:
      return state;
  }
};

export default ParticipantReducer;
