//Store
import { store } from '../store';

//Constants
import { SET_TRANSCRIPTIONS } from '../ReduxConstants';
import { METHOD } from '../../Constants/AppConstants';
import callAPI from '../../AxiosHelper/APICall';
import { URLS } from '../../AxiosHelper/ApiUrls';

export function setTranscriptions(data: object) {
    store.dispatch({
        type: SET_TRANSCRIPTIONS,
        payload: data
    })
}

// Call the API function to send invite email
export async function inviteParticipant(emails: string[]) {
    try {
        const response = await
            callAPI({
                method: METHOD.HTTP.POST,
                url: URLS.INVITE_PARTICIPANTS,
                requestBody: {
                    roomName: store.getState()?.RoomReducer?.roomName,
                    emails: emails
                },
            });
        // @ts-ignore
        return response?.response?.data;
    } catch (error) {
        console.error("Invite participant error: ", error);
    }
}


