import Avatar from "react-avatar";

//Style
import "./CustomAvatar.scss";

export type CustomAvatarType = {
  name?: string;
  size?: string,
  hideName?: boolean;
};

/**
 * @param {string}
 * @returns jsx to show the avatar
 */

const CustomAvatar = ({ name, size = '100', hideName = false }: CustomAvatarType) => {
  return (
    <div className="container">
      <Avatar name={name} round={true} textSizeRatio={3} size={size} />
      {
        !hideName &&
        <span className="name">{name}</span>
      }
    </div>
  );
};

export default CustomAvatar;
