import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Switch } from "antd";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";

//Actions
import { onToggleTranscription } from "../../Redux/Actions/ToolbarActions";

//Translations
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import CrossIcon from "../../Resources/Images/icadd21@2x.png"
import Vector1 from "../../Resources/Images/vector1.svg";

//Utility
import { formatMillisecondsToDate } from "../../Utility/Utils";

//Styles
import "./TranscriptPanel.scss";

export type TranscriptPanelType = {
    className?: string;
    isTranscriptionOn: boolean,
    onClose?: () => void
};

const TranscriptPanel: FunctionComponent<TranscriptPanelType> = ({
    className = "",
    onClose
}) => {
    const transcriptions = useSelector((state: any) => state.InCallReducer?.transcriptions);
    const transcriptionEnabled = useSelector((state: any) => state.ToolbarReducer?.transcriptionEnabled);
    const localParticipant = useSelector((state: any) => state.ParticipantReducer?.localParticipant);

    const containerRef = useRef<HTMLDivElement>(null);
    const [isAtBottom, setIsAtBottom] = useState(true);

    // Check if the user is at the bottom of the container
    const checkScrollPosition = () => {
        const container = containerRef.current;
        if (container) {
            const { scrollTop, scrollHeight, clientHeight } = container;
            setIsAtBottom(scrollHeight - scrollTop === clientHeight);
        }
    };

    // Auto-scroll to the bottom if the user is at the bottom
    useEffect(() => {
        const container = containerRef.current;
        if (container && isAtBottom) {
           // container.scrollTop = container.scrollHeight;
            scrollToElement()
        }
    }, [isAtBottom]);


    // Set up scroll event listener to track the scroll position
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', checkScrollPosition);
            return () => container.removeEventListener('scroll', checkScrollPosition);
        }
    }, []);

    // Simulate incoming messages
    useEffect(() => {
        const interval = setInterval(() => {
            if (containerRef.current) {
                if (isAtBottom) {
                    containerRef.current.scrollTop = containerRef.current.scrollHeight;
                }
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [isAtBottom]);


    // scroll to the element with id "targetElement" for the latest transcript text
    const scrollToElement = () => {
        const element = document.getElementById('targetElement' + (transcriptions?.length - 1));

        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    console.log('transcriptions: ', transcriptions);

    return (
        <div className={`transcript-panel ${className}`}>
            <div className="chatheader1">
                <div className="titleandtoggle">
                    <button className="arrowbackmobile1">
                        <img className="vector-icon27" alt="" src={Vector1} />
                    </button>
                    <div className="transcript">{getTranslation("transcript")}</div>
                    {localParticipant?.isModerator &&
                        <div className="switch22">
                            <Switch className="switch23" checked={transcriptionEnabled} style={{ width: 26 }} onChange={() => onToggleTranscription()} />
                        </div>
                    }
                </div>
                <button className="closebutton1" onClick={onClose}>
                    <img className="icadd2-icon9" alt="" src={CrossIcon} />
                </button>
            </div>

            <div className="bodytranscript1" ref={containerRef}            >
                {transcriptions?.map((transcription: any, index: number) => {
                    console.log('transcription data: ', transcription);
                    return (
                        <div className="messagetranscript" key={index} id={"targetElement" + index}>
                            <div className="gr-wrapper">
                                <Avatar name={transcription?.participantName} round size="40" />
                            </div>
                            <div className="transcriptcontent">
                                <div className="name-parent">
                                    <div className="name12">{transcription?.participantName}</div>
                                    <div className="name12">{formatMillisecondsToDate(transcription?.timestamp)}</div>
                                </div>
                                <div className="yeah-think-so">{transcription?.message}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default TranscriptPanel;
