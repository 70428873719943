import { FunctionComponent, useMemo, useRef, type CSSProperties } from "react";
import "./ToolBarButton.css";
import { getTranslation } from "../../Resources/Localization/i18n";
import { useSelector } from "react-redux";
import PortalPopup from "../PortalPopup/PortalPopup";
import EndCallPopup from "../EndCallPopup/EndCallPopup";
import { onEndCallButtonClick, onEndMeeting, onleaveCall, onMoreActionMobileClick } from "../../Redux/Actions/ToolbarActions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/RouteConstants";
import PortalDrawer from "../PortalDrawer/PortalDrawer";
import MobileActionBar from "../MobileActionBar/MobileActionBar";
import { store } from "../../Redux/store";

export type ToolBarButtonType = {
	className?: string;
	toolIcon?: string;
	toolIconLabel?: string;
	onClick?: any;
	ref?: any;
	isEndCallRef?: boolean;
	toolButtonClass?: string;

	/** Style props */
	toolBarButtonBorder?: CSSProperties["border"];
	toolBarButtonBackgroundColor?: CSSProperties["backgroundColor"];
	toolBarButtonPosition?: CSSProperties["position"];
	toolIconLabelColor?: CSSProperties["color"];
};

const ToolBarButton: FunctionComponent<ToolBarButtonType> = ({
	className = "",
	toolBarButtonBorder,
	toolBarButtonBackgroundColor,
	toolBarButtonPosition,
	toolIcon,
	toolIconLabel,
	toolIconLabelColor,
	onClick,
	toolButtonClass
}) => {


	const callActionsMoreMobileRef = useRef<HTMLButtonElement>(null);
	const callActionsEndCallRef = useRef<HTMLButtonElement>(null);
	const { moreActionClicked, isEndButtonClicked, moreActionMobileClicked } = useSelector((state: any) => state.ToolbarReducer);
	const navigate = useNavigate();
	const meetingId = useSelector(
		(state: any) => state.SalesReducer?.meetingId
	);

	const onLeaveMeeting = () => {
		onleaveCall()
		navigate(ROUTES.FEEDBACK)
	}

	const onEndMeetingForAll = () => {



		console.log('meetingId: ', store.getState().SalesReducer, "test", meetingId);

		onEndMeeting()
		navigate(ROUTES.FEEDBACK)
	}

	const toolBarButtonStyle: CSSProperties = useMemo(() => {
		return {
			border: toolBarButtonBorder,
			backgroundColor: toolBarButtonBackgroundColor,
			position: toolBarButtonPosition,
		};
	}, [
		toolBarButtonBorder,
		toolBarButtonBackgroundColor,
		toolBarButtonPosition,
	]);

	const toolIconLabelStyle: CSSProperties = useMemo(() => {
		return {
			color: toolIconLabelColor,
		};
	}, [toolIconLabelColor]);

	return (
		<>
			<button className={`toolbarbutton ${toolButtonClass ?? ""}`} style={toolBarButtonStyle} onClick={onClick}
				ref={(toolIconLabel === getTranslation("endCall")) ?
					callActionsEndCallRef : (toolIconLabel === getTranslation("more")) ? callActionsMoreMobileRef : null}
			>
				<img className="tooliconcontainer" alt="" src={toolIcon} />
				<div className="tooliconlabel" style={toolIconLabelStyle}>
					{toolIconLabel}
				</div>
			</button >

			{isEndButtonClicked && (toolIconLabel === getTranslation("endCall")) ? (
				<PortalPopup
					placement="Top right"
					relativeLayerRef={callActionsEndCallRef}
					onOutsideClick={onEndCallButtonClick}
				>
					<EndCallPopup onLeaveMeeting={onLeaveMeeting} onEndCall={onEndMeetingForAll} />
				</PortalPopup>
			) : null}

			{moreActionMobileClicked && (toolIconLabel === getTranslation("more")) && (
				<PortalDrawer
					overlayColor="rgba(0, 0, 0, 0.2)"
					placement="Bottom"
					onOutsideClick={onMoreActionMobileClick}
				>
					<MobileActionBar onClose={onMoreActionMobileClick} />
				</PortalDrawer>
			)}

		</>
	);


};

export default ToolBarButton;
