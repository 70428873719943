import { FunctionComponent } from "react";
import { Participant } from "proconf-web-sdk";

//Components
import CustomAvatar from "../CustomAvatar/CustomAvatar";
import VideoNameTag from "../VideoNameTag/VideoNameTag";

//Styles
import "./GridFrameGrid.scss";

export type GridFrameGridType = {
	className?: string;
	participants: Array<Participant>;
	localParticipant: Participant;
	remoteParticipants: Array<Participant>;
};

const GridFrameGrid: FunctionComponent<GridFrameGridType> = ({
	className = "",
	participants,
	localParticipant,
	remoteParticipants,
}) => {

	console.log('isTablet: ', participants?.length % 2);


	return (
		<>
			{participants?.length % 2 > 0 ?
				<div className={`gridframegrid ${participants?.length === 1 ? "d-flex one-grid-column" : " two-grid-columns"}`}>

					{remoteParticipants?.map((participant: any) => {
						return (
							<div className="griditemcall" key={participant?.sid}>

								<VideoNameTag
									username={participant?.identity}
									showMicOn={participant?.audioTracks[0]?.isEnabled ?? false}
									showMicOff={!participant?.audioTracks[0]?.isEnabled ?? true}
								/>

								<div className="previewwindownew">
									<div className="avatar-container">
										{participant?.videoTracks[0]?.isEnabled ? (
											<div className="container">
												<video
													id={"remote-video-" + participant?.sid}
													autoPlay
													playsInline
												/>
											</div>
										) : (
											<CustomAvatar name={participant?.identity} hideName={true} />
										)}
									</div>
								</div>
							</div>
						);
					})}
					<div className={`griditemcalllocal ${participants?.length === 3 && "l-50"}`}>

						<VideoNameTag
							username={localParticipant?.identity}
							showMicOn={localParticipant?.audioTracks[0]?.isEnabled ?? false}
							showMicOff={!localParticipant?.audioTracks[0]?.isEnabled ?? true}
							isLocalParticipant={true}
						/>

						<div className={participants?.length > 1 ? "previewwindownew" : "previewwindownew12"}>
							<div className="container">
								{localParticipant?.videoTracks[0]?.isEnabled ? (
									<video id={"local-video"} autoPlay playsInline />
								) : (
									<CustomAvatar name={localParticipant?.identity} hideName={true} />
								)}
							</div>
						</div>
					</div>
				</div>
				:
				<div className={`callcomponentwithoutaspectrati ${className}`}>
					<div className={`gridframegrid ${participants?.length % 2 === 0 ? "two-grid-columns" : "one-grid-column"}`}>
						{participants && participants?.map((participant: any) => {
							return (
								<div className="griditemcall" key={participant?.sid}>
									<VideoNameTag
										username={participant?.identity}
										showMicOn={participant?.audioTracks[0]?.isEnabled ?? false}
										showMicOff={!participant?.audioTracks[0]?.isEnabled ?? true}
										isLocalParticipant={participant?.sid === localParticipant?.sid ?? false}
									/>
									<div className="previewwindownew">
										<div className="avatar-container">
											{participant?.videoTracks[0]?.isEnabled ? (
												<div className="container">
													<video
														id={participant?.sid === localParticipant?.sid ? "local-video" : "remote-video-" + participant?.sid}
														autoPlay
														playsInline
													/>
												</div>
											) : (
												<CustomAvatar name={participant?.identity} hideName={true} />
											)}
										</div>
									</div>
								</div>

							);
						}).reverse()
						}
					</div>
				</div>
			}
		</>
	);
};

export default GridFrameGrid;

