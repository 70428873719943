import { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";
import { Track } from "proconf-web-sdk";

//Custom Components
import CustomDropDown from "../../Common Components/CustomDropDown/CustomDropdown";
import Logout from "../../Common Components/LogoutMenu/Logout";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import CustomAvatar from "../../Common Components/CustomAvatar/CustomAvatar";
import ProfileMenu from "../../Common Components/ProfileMenu/ProfileMenu";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import ProConfLogo from "../../Resources/Images/ProConfLogo@2x.png";
import { ReactComponent as Arrowback } from "../../Resources/Images/arrowback.svg";
import { ReactComponent as MicOff } from "../../Resources/Images/IconMicOffSmall.svg";
import { ReactComponent as MicOn } from "../../Resources/Images/IconMicOnSmall.svg";
import { ReactComponent as VideoOn } from "../../Resources/Images/IconVideoOnSmall.svg";
import { ReactComponent as VideoOff } from "../../Resources/Images/IconVideoOffSmall.svg";
import { ReactComponent as SpeakerOff } from "../../Resources/Images/ic_speaker_off.svg";

//Constants
import { CLEAR_NOTIFICATIONS, ON_MIC_MUTE_UNMUTE, ON_VIDEO_MUTE_UNMUTE } from "../../Redux/ReduxConstants";
import { CONFIGURATIONS } from "../../Constants/AppConstants";

//Store
import { store } from "../../Redux/store";

//Utility
import { getConnectedDevices, getUserMedia } from "../../Utility/DeviceUtils";
import { handleKeyPressForName } from "../../Utility/Utils";

//Services
import Proconfservice from "../../Services/ProConfService";

//Styles
import "./MeetingPreview.scss";
import { isMobile } from "react-device-detect";
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";

interface MeetingPreviewProps {
	isJoinFlow: boolean;
	isCreateMeeting: boolean;
	onBackClick: () => void;
	onStartClick: (roomName: string) => void;
	onLogoutClick: () => void;
	setStartClicked: (value: boolean) => void;
	startClicked: boolean;
	roomName: string;
	setRoomName: (value: string) => void
}

/**
 * @description User can select the devices like audio, video, speakers etc
 *  from this page before starting the meeting
 * @author Ruchika Phalke <ruchika.phalke@springct.com>
 * @return {FunctionComponent} jsx component to render a Meeting Preview page 
*/
const MeetingPreview: React.FC<MeetingPreviewProps> = ({
	isJoinFlow,
	isCreateMeeting,
	onBackClick,
	onStartClick,
	onLogoutClick,
	setStartClicked,
	startClicked,
	roomName,
	setRoomName
}) => {
	/* #region Variable Declaration */

	//useSelector cannot be called contionally, so used getState method here
	const userName = store.getState()?.LoginReducer.userName || store.getState()?.RoomReducer.userName

	const micstate = useSelector((state: any) => state.ToolbarReducer.audioMute)
	const videostate = useSelector((state: any) => state.ToolbarReducer.videoMute);
	const isInitDone = useSelector((state: any) => state.ProConfReducer.isInitDone);

	// State to manage which components are visible
	const { configurations } = useSelector((state: any) => state.LoginReducer);

	//State variables
	const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
	const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
	const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);

	const [selectedMic, setSelectedMic] = useState({ deviceId: "", label: "" });
	const [selectedCamera, setSelectedCamera] = useState({ deviceId: "", label: "" });
	const [selectedSpeaker, setSelectedSpeaker] = useState({ deviceId: "", label: "" });
	const [micMute, setMicMute] = useState(micstate);
	const [videoMute, setVideoMute] = useState(videostate);
	const [localAudiotrack, setLocalAudiotrack] = useState({} as Track);
	const [localVideotrack, setLocalVideotrack] = useState({} as Track);

	const [isLoading, setLoading] = useState(false);
	const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
	const [isFromLink, setIsFromLink] = useState(false);
	const [enableControls, setEnableControls] =  useState(true);

	let deviceListExists = false;


	// Get previously selected devices by the user(in case of page refresh)
	const userCamera = useSelector((state: any) => state.ToolbarReducer.selectedCamera);
	const userMic = useSelector((state: any) => state.ToolbarReducer.selectedMic);
	const userSpeaker = useSelector((state: any) => state.ToolbarReducer.selectedSpeaker);

	//Ref
	const profileRef = useRef<HTMLButtonElement>(null);
	/* #endregion */

	useEffect(() => {
		// Check if user comes from invite link and disable roomname field
		if (roomName && roomName.trim().length > 0) {
			setIsFromLink(true);
		}
		return () => {
			// Remove the event listener on unmount
			navigator.mediaDevices.ondevicechange = null;
			setIsFromLink(false);
		}
	}, []);

	// Listen for media devices change and update the list and set new local tracks
	// Debounce function added as we are getting devicechange event multiple times and tracks are getting created multiple times
	function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
		let timer: ReturnType<typeof setTimeout>;
		return function (this: any, ...args: Parameters<T>) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, delay);
		};
	}

	// Event handler function
	function handleDeviceChangeEvent() {
		if (deviceListExists) {
			fetchDevices();
		} else {
			// This is the case when unnecessarily devices list gets fetched again due to onDeviceChange event after initial mount Fix#PR-16
			deviceListExists = true;
		}
	}
	const debouncedEventHandler = debounce(handleDeviceChangeEvent, 2000);
	navigator.mediaDevices.ondevicechange = debouncedEventHandler;

	/**
	 * @method fetchDevices
	 * @description Get All Available Devices in the navigator wrt to their media type
	 * @author Pranjali Tagare <pranjali.tagare@springct.com>
	*/
	const fetchDevices = async () => {

		await clearTracks();
		// Temp fix for https://proconf.atlassian.net/browse/PR-56
		// getUserMedia is used to ask browser for permissions before fetching devices and creating tracks
		let deviceConfiguration = { audio: true, video: CONFIGURATIONS.VIDEO_CALL in configurations ? (isMobile ? true : { width: 4096, height: 2160 }) : false };
		console.log("deviceConfiguration: ", deviceConfiguration, configurations);

		getUserMedia(deviceConfiguration).then(async () => {

			const audioInputDevices = await getConnectedDevices("audioinput");
			const audioOutputDevices = await getConnectedDevices("audiooutput");

			let camera: any;
			let videoDevices;
			let mic: any;
			let speaker;
			if (CONFIGURATIONS.VIDEO_CALL in configurations) {
				videoDevices = await getConnectedDevices("videoinput");

				if (videoDevices) {
					const matchingDevice = videoDevices.find(device => device?.label === userCamera?.label);
					setVideoDevices(videoDevices);
					if (userCamera?.deviceId && matchingDevice) {
						camera = userCamera;
						setSelectedCamera(userCamera);
					} else {
						camera = videoDevices?.[0];
						setSelectedCamera(videoDevices?.[0]);
					}
				}
			}
			if (audioInputDevices) {
				setAudioInputDevices(audioInputDevices);
				const matchingDevice = audioInputDevices.find(device => device?.label === userMic?.label);
				if (userMic?.deviceId && matchingDevice) {
					mic = userMic;
					setSelectedMic(userMic);
				} else {
					mic = audioInputDevices?.[0];
					setSelectedMic(audioInputDevices?.[0]);
				}
			}

			if (audioOutputDevices) {
				const matchingDevice = audioOutputDevices.find(device => device?.label === userSpeaker?.label);
				setAudioOutputDevices(audioOutputDevices);
				if (userSpeaker?.deviceId && matchingDevice) {
					speaker = userSpeaker;
					setSelectedSpeaker(userSpeaker);
				} else {
					speaker = audioOutputDevices?.[0];
					setSelectedSpeaker(audioOutputDevices?.[0]);
				}
			}

			//TODO: create action method in action file
			store.dispatch({ type: 'SET_SELECTED_SPEAKER', payload: audioOutputDevices?.[0] });
			// @ts-ignore
			await setTracks(mic, camera);
		});
	};

	/**
	 * @method setTracks
	 * @description Set local tracks everytime a new track is selected from dropdown,
	 *  which can be passed while creating room
	 * @author Pranjali Tagare <pranjali.tagare@springct.com>
	*/
	const setTracks = async (audioD: any, videoD?: any) => {
		let cameraDevice = videoD || null;
		let micdevice = audioD;
		setEnableControls(false);
		setStartClicked(true);
		let deviceObj = CONFIGURATIONS.VIDEO_CALL in configurations ?{
			cameraDeviceId: cameraDevice ? cameraDevice?.deviceId : null,
			micDeviceId: micdevice?.deviceId,
		} : {
			micDeviceId: micdevice?.deviceId,
		};

		// Clear previous tracks
		await clearTracks().then(async () => {
			setTimeout(async () => {
				//if (cameraDevice && micdevice) {
					await Proconfservice.createTracks(deviceObj).then((localtracks) => {
						localtracks?.map((track: Track) => {
							if (track?.kind === "audio") {
								setLocalAudiotrack(track);
								document?.getElementById("previewwindowsettings")?.appendChild(track?.attach() as HTMLAudioElement);
								if (micMute) {
									track?.disable();
								}
							} else if (track?.kind === "video" && CONFIGURATIONS.VIDEO_CALL in configurations) {
								setLocalVideotrack(track);
								track?.attach(document.getElementById("video-preview") as HTMLVideoElement);
								if (videoMute) {
									track?.disable();
								}
							}
							setStartClicked(false);
							setEnableControls(true);
						});
					}).catch((err) => {
						console.error(err, err?.message);
						setStartClicked(false);
						setEnableControls(true);
						setErrorNotification(getTranslation("deviceInUse"));
						setTimeout(() => {
							store.dispatch({ type: CLEAR_NOTIFICATIONS });
						}, 2000);
					});
				//};
			}, 1000);
		});
	}

	/**
	 * @method clearTracks
	*/
	const clearTracks = async () => {
		if (localAudiotrack.sid) {
			await localAudiotrack?.stop();
		}
		if (localVideotrack.sid) {
			await localVideotrack?.stop();
		}
	}

	useEffect(() => {
		setLoading(true);
		//unmount
		return () => {
			navigator.mediaDevices.removeEventListener('devicechange', fetchDevices);
			clearTracks();
		};
	}, []);

	useEffect(() => {
		if (isInitDone && !localAudiotrack.sid && !localVideotrack.sid) {
			if (isInitDone) {
				(async () => {
					await fetchDevices().then(async () => {
						await clearTracks();
					});
				})();
			}
			setLoading(false);
		}
	}, [isInitDone]);


	useEffect(() => {
		// @ts-ignore
		if (selectedSpeaker && selectedSpeaker?.deviceId) {
			store.dispatch({ type: 'SET_SELECTED_SPEAKER', payload: selectedSpeaker });
		}
	}, [selectedSpeaker]);

	useEffect(() => {
		// @ts-ignore
		if (selectedMic && selectedMic?.deviceId) {
			store.dispatch({ type: 'SET_SELECTED_MIC', payload: selectedMic });
		}
	}, [selectedMic]);

	useEffect(() => {
		// @ts-ignore
		if (selectedCamera && selectedCamera?.deviceId) {
			store.dispatch({ type: 'SET_SELECTED_CAMERA', payload: selectedCamera });
		}
	}, [selectedCamera]);

	/* #region Event Handlers */

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		let value = e.target.value;
		switch (type) {
			case "roomName":
				setRoomName(value);
				break;
		}
	};

	/**
	 * on change selection of media devices
	 */
	const onChangeMicroPhone = (e: any, option: any) => {
		e.preventDefault();
		setSelectedMic(option);
		setTracks(option, selectedCamera);
	};

	const onChangeCamera = (e: any, option: any) => {
		e.preventDefault();
		setSelectedCamera(option);
		setTracks(selectedMic, option);
	};

	const onChangeSpeaker = (e: any, option: any) => {
		e.preventDefault();
		setSelectedSpeaker(option);
		store.dispatch({ type: 'SET_SELECTED_SPEAKER', payload: option });
	};

	const toggleMic = async () => {
		if (micMute) {
			await Proconfservice.unmuteAudio();
		} else {
			await Proconfservice.muteAudio();
		}
		setMicMute(!micMute);
		store.dispatch({ type: ON_MIC_MUTE_UNMUTE });
	};

	const toggleVideo = async () => {

		if (videoMute) {
			await Proconfservice.unmuteVideo();
		} else {
			await Proconfservice.muteVideo();
		}
		setVideoMute(!videoMute);
		store.dispatch({ type: ON_VIDEO_MUTE_UNMUTE });
	};

	const onBackButtonClick = async () => {
		await clearTracks();
		onBackClick();
	}

	const onStart = async () => {
		if (roomName) {
			setStartClicked(true);
			onStartClick(roomName);
		}
	};

	/**
	 * onclick for profile menu
	 * consists option to logout from the system
	 */
	const openLogoutPopup = useCallback(() => {
		setLogoutPopupOpen(true);
	}, [isLogoutPopupOpen]);

	const closeLogoutPopup = useCallback(() => {
		setLogoutPopupOpen(false);
	}, [isLogoutPopupOpen]);

	/**
	 * close the logout dropdown
	 */
	const onLogout = useCallback(() => {
		onLogoutClick()
		setLogoutPopupOpen(false);
	}, [isLogoutPopupOpen]);

	/* #endregion */


	/* #region Renderers */
	/**
	 * 
	 * @returns jsx for logout dropdown
	 * will be displayed onclick of profile
	 */
	const renderLogoutDropdown = () => {
		return (
			<PortalPopup relativeLayerRef={profileRef}
				placement="Bottom right" onOutsideClick={closeLogoutPopup}
			>
				<Logout onLogoutClick={onLogout} />
			</PortalPopup>)
	}

	/**
	 * 
	 * @returns jsx to display roomname field and
	 * device selections
	 */
	const renderDevices = () => {
		return (
			<div className="entermeetingdetails">
				<div className="formfields">
					<div className="enterroomname">
						<div className="inputfield">
							<div className="titletext">{getTranslation("roomName")}</div>
							<Input
								className="inputfield1"
								size="large"
								onChange={(e) => handleInputChange(e, "roomName")}
								name="roomName"
								placeholder={getTranslation("enterRoomName")}
								type="text"
								required
								maxLength={50}
								value={roomName || ""}
								onKeyDown={handleKeyPressForName}
								disabled={isFromLink}
							/>
						</div>
					</div>
					<div className="chooseDevices">
						<div className="titletext1">
							{getTranslation("chooseDevices")}
						</div>
						<div className="inputdropdown">
							<div className="label1">{getTranslation("speaker")}</div>

							<CustomDropDown
								options={audioOutputDevices}
								selectedOption={selectedSpeaker}
								onSelectChange={onChangeSpeaker}
							/>
						</div>
						<div className="inputdropdown">
							<div className="label1">{getTranslation("microphone")}</div>
							<CustomDropDown
								options={audioInputDevices}
								selectedOption={selectedMic}
								onSelectChange={onChangeMicroPhone}
							/>
						</div>
						{CONFIGURATIONS.VIDEO_CALL in configurations &&
							<div className="inputdropdown">
								<div className="label1">{getTranslation("camera")}</div>

								<CustomDropDown
									options={videoDevices}
									selectedOption={selectedCamera}
									onSelectChange={onChangeCamera}
								/>
							</div>
						}
					</div>
				</div>
				<div className="buttonprimary-wrapper">
					<button className="buttonprimary" disabled={!roomName.trim() || startClicked || (!localVideotrack?.sid && !localAudiotrack?.sid)} onClick={onStart}>
						<b className="buttonlabel">{isJoinFlow || !isCreateMeeting ? getTranslation("join") : getTranslation("start")}</b>
					</button>
				</div>
			</div>
		)
	}

	const renderVideoPreview = () => {
		return (
			<div className="videopreview">
				<div className="titletext">{getTranslation("videoPreview")}</div>
				<div className={videoMute ? "previewwindowsettings" : "previewwindowsettings video-fit"}>
					<div className="outerpreview">
						<video className={`previewwindow ${videoMute ? 'hidden' : ''}`} id="video-preview" />
						<div className={`avatar-container ${videoMute ? '' : 'hidden'}`}>
							<CustomAvatar name={userName} />
						</div>
					</div>
					<div className="controls">
						<div className="callaudiovideocontrols">
							<button
								className={localAudiotrack && localAudiotrack?.sid && enableControls ? "controloptionsmall" : "controloptionsmall disabled-icon"}
								onClick={() => toggleMic()}
							>
								{micMute ?
									<MicOff className={localAudiotrack && localAudiotrack?.sid ? "controliconssmall" : "controliconssmall disabled-icon"} />
									: <MicOn className={localAudiotrack && localAudiotrack?.sid ? "controliconssmall" : "controliconssmall disabled-icon"} />
								}
								<div className="already-have-an">
									{getTranslation("mic")}
								</div>
							</button>
							<button
								className={localVideotrack && localVideotrack?.sid && enableControls ? "controloptionsmall" : "controloptionsmall disabled-icon"}
								onClick={() => toggleVideo()}
							>
								{videoMute ?
									<VideoOff className={localVideotrack && localVideotrack?.sid ? "controliconssmall" : "controliconssmall disabled-icon"} />
									: <VideoOn className={localVideotrack && localVideotrack?.sid ? "controliconssmall" : "controliconssmall disabled-icon"} />
								}
								<div className="already-have-an">
									{getTranslation("video")}
								</div>
							</button>
							<button className="controloptionsmall2">
								<SpeakerOff className="controliconssmall" />

								<div className="already-have-an">
									{getTranslation("speaker")}
								</div>
							</button>
							<button className="controloptionsmall2">
								<SpeakerOff className="controliconssmall" />
								<div className="already-have-an">
									{getTranslation("speaker")}
								</div>
							</button>
						</div>
						{/* <div className="backgroundblur">
							<div className="bgblur-parent">
								<div className="bgblur">
									<img className="vector-icon2" alt="" src={Vector1} />
								</div>
								<div className="already-have-an4">
									{getTranslation("bgBlur")}
								</div>
							</div>
							<Switch
								className="switch2"
								style={{
									width: 26,
								}}
							/>
						</div> */}
					</div>
				</div>
			</div>
		)
	}
	/* #endregion */



	return (
		<>
			{isLoading && <LoadingSpinner />}
			<div className="create-meeting">
				<div className="whitebannerproconf">
					<header className="header">
						<div className="nav-items" onClick={openLogoutPopup}>
							<img className="logo-icon" alt="ProConf Logo" src={ProConfLogo} />
							{/* {!isJoinFlow &&
								<ProfileMenu className="profile1" buttonRef={profileRef} openLogoutPopup={openLogoutPopup} />
							} */}
						</div>
					</header>

				</div>
				<div className="previewmeetingsection">
					<div className="createmeetingsection">
						<div className="createmeetinglist">
							<div className="meetinglabel">
								<button className="arrowback">
									<Arrowback
										className={enableControls ? "vector-icon1" : "vector-icon1 disabled-icon"}
										onClick={onBackButtonClick}
									/>
								</button>
								<b className="meetingtext">{isJoinFlow || !isCreateMeeting ? getTranslation("joinMeetingText") : getTranslation("createMeeting")}</b>
							</div>
						</div>

						<div className="previewsection">
							{renderDevices()}
							{CONFIGURATIONS.VIDEO_CALL in configurations && renderVideoPreview()}
						</div>
					</div>
				</div>
			</div>


			{isLogoutPopupOpen && renderLogoutDropdown()}
		</>
	);
};

export default MeetingPreview;
