import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import { ReactComponent as EndCallIcon } from "../../Resources/Images/union-3.svg"
import { ReactComponent as LeaveMeetingIcon } from "../../Resources/Images/vector6.svg"

//Styles
import "./EndCallPopup.scss";

export type EndCallPopupType = {
    className?: string;
    onLeaveMeeting?: () => void;
    onEndCall?: () => void;
};

const EndCallPopup: FunctionComponent<EndCallPopupType> = ({
    className = "",
    onLeaveMeeting,
    onEndCall,
}) => {
   
    const isModerator = useSelector((state: any) => state.ParticipantReducer.localParticipant?.isModerator);
   
    return (
        <div className={`buttonleave ${className}`}>
            <button className="buttonendcall" onClick={onEndCall} disabled={!isModerator}>
                <div className="iconendcall1">
                    <EndCallIcon className="union-3-icon1" />
                </div>
                <div className="end-for-all">{getTranslation("endForAll")}</div>
            </button>
            <button className="hidedefault3" onClick={onLeaveMeeting}>
                <div className="iconendcall1">
                    <LeaveMeetingIcon className="vector-icon34" />
                </div>
                <div className="leave-meeting">{getTranslation("leaveMeeting")}</div>
            </button>
        </div>
    );
};

export default EndCallPopup;
