import { callSalesAPI } from "../../../AxiosHelper/SalesAPICall";
import { SALES_URLS } from "../../../AxiosHelper/ApiUrls";
import { API_STATUS, METHOD } from "../../../Constants/AppConstants";
import { setErrorNotification, setSuccessNotification } from "../../../Redux/Actions/NotificationAction";
import { setDataIntoReduxStore } from "../../../Redux/Actions/StoreAction";
import { SET_AUTH_TOKEN, SET_PROFILE_NAME, SET_ROOM_ID } from "../../../Redux/ReduxConstants";
import { store } from "../../../Redux/store";
import { getTranslation } from "../../../Resources/Localization/i18n";
import callAPI from "../../../AxiosHelper/APICall";


export async function updateVisitedCount(rid: string
): Promise<any> {

    let url = SALES_URLS.UPDATE_VISITED_COUNT + `${rid}`

    const response = await
        callSalesAPI({
            method: METHOD.HTTP.GET,
            url: url,
        })

    console.log('response: ', response);

    return response;
}

export async function requestADemoAction(requestFormData: {}) {
    const response = await
        callSalesAPI({
            method: METHOD.HTTP.POST,
            url: SALES_URLS.REQUEST_DEMO,
            requestBody: requestFormData
        })

    console.log('response: ', response);

    return response;
}

export async function fetchTokenizeURL(userName: string, meetingName: string, guestRoomToken: string,
) {
    let url = SALES_URLS.TOKENIZE_URL + `${guestRoomToken}`

    const response = await
        callSalesAPI({
            method: METHOD.HTTP.POST,
            url: url,
            requestBody: { "creator_name": userName, "meeting_name": meetingName }
        })
    console.log('response: ', response, response?.reponse?.data?.tokenizeURL);

    if (response?.status === API_STATUS.SUCCESS) {
        let tokenizeURL = response?.response?.data?.tokenizeURL
        const index = tokenizeURL.indexOf("demoapp/#/");
        let token = tokenizeURL.substring(index + "demoapp/#/".length).trim(); // Extract after the keyword
        let meetingId = response?.response?.data?.meeting_id;
        console.log('meetingId: ', meetingId);
        store.dispatch({ type: "SET_MEETING_ID", payload: meetingId })

        setDataIntoReduxStore(SET_AUTH_TOKEN, token)
        setDataIntoReduxStore(SET_PROFILE_NAME, userName)
    }

    return response;
}

export async function submitUserFeedback(
    name: string, organization: string, email: string, comments: string
): Promise<any> {
    let meetingId = store.getState().SalesReducer?.meetingId;
    console.log('meetingId: ', store.getState().SalesReducer, meetingId);

    const response = await
        callSalesAPI({
            method: METHOD.HTTP.POST,
            url: SALES_URLS.FEEDBACK,
            requestBody: {
                "meeting_id": meetingId,
                "user_name": name,
                "organization_name": organization,
                "email": email,
                "comments": comments
            }
        })

    console.log('submitUserFeedback response: ', response, response?.status);

    if (response?.status === API_STATUS.SUCCESS) {
        console.log('response?.response?.status: ', response?.response?.status);
        setSuccessNotification(getTranslation("feedbackSubmitted"))
    }
    else {
        setErrorNotification(response?.response?.data?.error?.message)
    }

    return response;

}

export async function updateTimeStamp(action: string
): Promise<any> {
    const participantId = store.getState().SalesReducer?.participantId;
    const participantName = store.getState().SalesReducer?.participantName;

    const currentDate = new Date();
    const customDateTime = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    console.log('customDateTime: ', participantId, customDateTime, action, participantName);

    const response = await
        callSalesAPI({
            method: METHOD.HTTP.PUT,
            url: SALES_URLS.PARTICIPANT,
            requestBody: {
                "participant_id": participantId,
                "name": participantName,
                "join_time": action === "joining" ? customDateTime : undefined,
                "leave_time": action === "leaving" ? customDateTime : undefined,
            }
        })

    console.log('response: ', response);

    return response;

}

export async function getParticipantDetails(): Promise<any> {

    const participantId = store.getState().SalesReducer?.participantId;
    let url = SALES_URLS.PARTICIPANT + `/${participantId}`

    const currentDate = new Date();
    const customDateTime = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;


    const response = await
        callSalesAPI({
            method: METHOD.HTTP.GET,
            url: url,
        })
    return response;

}

// Set room Id in room reducer
export function setRoomId(roomId: string) {
    store.dispatch({
        type: SET_ROOM_ID,
        payload: roomId,
    })
}

// Call the API function to send invite email
export async function inviteSalesParticipants(emails: string[]) {
    console.log('store.getState().LoginReducer?.userName: ', store.getState().LoginReducer?.userName);

    try {
        const response = await
            callSalesAPI({
                method: METHOD.HTTP.POST,
                url: SALES_URLS.INVITE_PARTICIPANTS,
                requestBody: {
                    room_id: store.getState().RoomReducer?.roomId,
                    moderator_name: store.getState().LoginReducer?.userName,
                    meet_name: store.getState()?.RoomReducer?.roomName,
                    emails: emails
                },
            });
        // @ts-ignore
        return response?.response?.data;
    } catch (error) {
        console.error("Invite participant error: ", error);
    }
}

// Get copy invite link for sales
export async function getCopyInviteLink() {
    try {
        const response = await
            callSalesAPI({
                method: METHOD.HTTP.POST,
                url: SALES_URLS.COPY_INVITE_LINK,
                requestBody: {
                    room_id: store.getState().RoomReducer?.roomId,
                    meet_name: store.getState()?.RoomReducer?.roomName,
                },
            });
        // @ts-ignore
        return response?.response?.data;
    } catch (error) {
        console.error("Error getting copy invite link: ", error);
    }
}

export async function getSummaryFeedback() {

    try {
        const response = await
            callAPI({
                method: METHOD.HTTP.POST,
                url: SALES_URLS.SUMMARY_FEEDBACK,
                requestBody: {
                    room_id: store.getState().RoomReducer?.roomId,
                    roomName: store.getState()?.RoomReducer?.roomName,
                },
            });
        console.log('response: ', response);

        // @ts-ignore
        return response?.response?.data;
    } catch (error) {
    }
}