import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";

//Custom Components
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import Logout from "../../Common Components/LogoutMenu/Logout";
import ProfileMenu from "../../Common Components/ProfileMenu/ProfileMenu";
import MeetingSummary from "../../Common Components/MeetingSummary/MeetingSummary";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import logo from "../../Resources/Images/logo@2x.png"
import Illustration from "../../Resources/Images/Illustration1@2x.png"
import union from "../../Resources/Images/union.svg"
import union1 from "../../Resources/Images/union1.svg"
import Illustration2 from "../../Resources/Images/Illustration2@2x.png"

//Utility
import { getFormattedDateTime } from "../../Utility/Utils";

//Styles
import "./LandingPage.scss";

interface LandingPageProps {
    meetingList: any;
    summary: { meeting_title: "", start_time: "", discussion_points: [], action_items: [], conclusions: [], attendees: [] },
    getCallSummary: (uniqueId: string) => {}
    createMeetingButton: () => {}
    joinMeeting: () => {}
    setCallSummary: (summary: {}) => {}
    onLogoutClick: () => void
}

const LandingPage: React.FC<LandingPageProps> = ({ meetingList, getCallSummary, summary, createMeetingButton, joinMeeting, onLogoutClick, setCallSummary }) => {
    //state variables
    const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
    const [isSummaryPopUpOpen, setIsSummaryPopUpOpen] = useState(false);

    const isInitDone = useSelector((state: any) => state.ProConfReducer.isInitDone);

    //ref
    const profileRef = useRef<HTMLButtonElement>(null);

    /* #region Event handlers */


    /**
     * @method onJoinMeetingClick
     * @description onClick for join btn from the jsx component which accepts the room name
     * start the meeting with the entered room name
     * @author Ruchika Phalke <ruchika.phalke@springct.com>
    */
    const onJoinMeetingClick = () => {
        joinMeeting();
    }

    const openLogoutPopup = useCallback(() => {
        setLogoutPopupOpen(true);
    }, [isLogoutPopupOpen]);

    const closeLogoutPopup = useCallback(() => {
        setLogoutPopupOpen(false);
    }, [isLogoutPopupOpen]);

    /**
     * close the logout dropdown
     */
    const onLogout = useCallback(() => {
        onLogoutClick()
        setLogoutPopupOpen(false);
    }, [isLogoutPopupOpen]);

    /**
     * @method onOpenSummary
     * @param meeting selected meeting
     * @description get the details of the selected meeting
     * @author Ruchika Phalke <ruchika.phalke@springct.com>
    */
    const onOpenSummary = (meeting: any) => {
        getCallSummary(meeting?.unique_id)
        setIsSummaryPopUpOpen(true)
    }

    const onCloseSummary = () => {
        setIsSummaryPopUpOpen(false)
        setCallSummary({})
    }
    /* #endregion */

    /* #region Renderers */

    /**
     * 
     * @returns jsx for logout dropdown
     * will be displayed onclick of profile
     */
    const renderLogoutDropdown = () => {
        return (
            <PortalPopup placement="Bottom right"
                relativeLayerRef={profileRef}
                onOutsideClick={closeLogoutPopup}
            >
                <Logout onLogoutClick={onLogout} />
            </PortalPopup>

        )
    }

    /**
     * 
     * @returns jsx for banner/hero section on the landing page
     */
    const renderBannerContent = () => {
        return (
            <div className="bannercontent">
                <img
                    className="bannerimageleft-icon"
                    alt="Man on a video call"
                    src={Illustration}
                />
                <div className="content1">
                    <div className="heading">{getTranslation("seamlessVideo")}</div>
                    <div className="actions">
                        <button className="buttonprimarywithicon" disabled={!isInitDone} onClick={() => createMeetingButton()}>
                            <div className="icadd">
                                <img className="union-icon12" alt="" src={union} />
                            </div>
                            <b className="createmeeting">{getTranslation("createMeeting")}</b>
                        </button>
                        <button className="buttonwhitewithicon" disabled={!isInitDone} onClick={() => onJoinMeetingClick()}>
                            <div className="icjoin">
                                <img className="union-icon12" alt="" src={union1} />
                            </div>
                            <b className="join">{getTranslation("joinMeeting")}</b>
                        </button>
                    </div>
                </div>
                <img
                    className="bannerimageright-1-icon"
                    alt=" Video call illustration"
                    src={Illustration2}
                />
            </div>
        )
    }

    /**
     * 
     * @returns jsx to render list of previous meetings
     * fetched from web-sdk
     */
    const renderMeetingList = () => {
        return (
            meetingList?.map((meeting: any) => {
                let roomName = (meeting as { room_name: string }).room_name;
                let unique_id = (meeting as { unique_id: string }).unique_id;
                let isSummaryAvailable = (meeting as { is_summary_available: string }).is_summary_available;

                return (
                    <div className="cardrows" key={unique_id}>
                        <div className="datetime">
                            <div className="time">{getFormattedDateTime(meeting?.created_at)}</div>
                        </div>
                        <div className="meetingname">
                            <b className="meetingname1">
                                {roomName}
                            </b>
                        </div>
                        {isSummaryAvailable ?
                            <button className="textlink cursor-pointer" onClick={() => onOpenSummary(meeting)}>
                                <b className="linktext">{getTranslation("viewSummary")}</b>
                            </button> :
                            <span className="textlink"><b className="linktext">--</b></span>
                        }
                    </div >
                )
            })
        )
    }

    /**
    * 
    * @returns jsx for view summary of the past meeting
    * It will be displayed onclick of profile
    */
    const renderViewSummary = () => {
        return (
            <PortalPopup className="join-meeting-popup-container">
                <MeetingSummary summary={summary} onClose={onCloseSummary} />
            </PortalPopup>
        )
    }

    /* #endregion */


    return (
        <>
            <div className="landing-page">
                <div className="banner1">
                    <header className="header1">
                        <div className="nav-items1">
                            <img className="logo-icon2" alt="" src={logo} />
                            <ProfileMenu className="profile1" buttonRef={profileRef} openLogoutPopup={openLogoutPopup} />
                        </div>
                    </header>
                    {renderBannerContent()}
                </div>
                <div className="meetingsection">
                    <div className="pastmeetinglist">
                        <div className="meetinglabel">
                            <b className="meetingtext">{getTranslation("pastMeetings")}</b>
                        </div>
                        <div className="listofmeetings">
                            {renderMeetingList()}
                            {isSummaryPopUpOpen && renderViewSummary()}

                        </div>
                    </div>
                </div>
            </div>

            {isLogoutPopupOpen && renderLogoutDropdown()}

        </>

    );
};

export default LandingPage;
