import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { handleKeyPress, validateEmail } from "../../Utility/Utils";

import HeaderNavigation from "../CommonComponents/HeaderNavigation/HeaderNavigation";
import FeatureCards from "../KeyFeatures/KeyFeatures";
import Footer from "../CommonComponents/Footer/Footer";

//Assets
import HeroImage from "../../Resources/Images/sales-images/imagebannercommon@2x.webp";

//Styles
import "./Feedback.scss"
import "../CommonComponents/HeroSection/HeroSection.scss"
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";


interface FeedbackFormProps {
    onSkipClick: () => void;
    onFeedBackSubmit: (name: string,
        organization: string,
        email: string,
        comments: string) => void;
    getInviteeParticipantDetails: (pid: number) => void;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ onSkipClick, onFeedBackSubmit, getInviteeParticipantDetails }) => {
    const [name, setName] = useState("");
    const [organization, setOrganization] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [comments, setComments] = useState("");

    const feedbackButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        getParticipantDetails()
    }, []);

    async function getParticipantDetails() {
        const participantId = sessionStorage.getItem("participantId");
        const pid: number = participantId ? JSON.parse(participantId) : 0;
        const participantName = sessionStorage.getItem("participantName");

        const pName: string = participantName ? JSON.parse(participantName) : "";

        const un = sessionStorage.getItem("uname");
        const uname: string = un ? JSON.parse(un) : "";

        let userName = ""
        if (pName) {
            userName = pName
        } else {
            userName = uname
        }
        let response = await getInviteeParticipantDetails(pid);
        setName(userName);
        //setEmail(response?.email)
        console.log('response: ', response, pName, uname, userName);
    }

    // Handler function to update state when input value changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        switch (type) {
            case "name":
                setName(e.target.value)
                break
            case "organization":
                setOrganization(e.target.value)
                break
            case "email":
                setEmail(e.target.value)
                setError("")
                break
        }
    }

    const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>, type: string) => {
        setComments(e.target.value)
    }

    const onSubmitClick: React.MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (validateEmail(email) && name?.trim()?.length > 0 && organization?.trim()?.length > 0 && comments?.trim()?.length > 0) {
            onFeedBackSubmit(name, organization, email, comments);
            {/* @ts-ignore */ }
            feedbackButtonRef.current.disabled = true;
            setError("")
        }
        else {
            //setError("Please enter valid email!")
            setErrorNotification("Please enter valid data!")
        }
    }

    return (

        <div className="proconflanding-intro">
            <HeaderNavigation />
            <div className={`banner3`}>
                <div className="content18">
                    <img
                        className="imagebannercommon-icon4"
                        alt=""
                        src={HeroImage}
                    />
                    <div className="thankyousection">
                        <div className="banner3title3">
                            <div className="thank-you-for-using-proconf-parent1">
                                <div className="thank-you-for4">Thank you for using ProCONF</div>
                                <div className="subtext120">
                                    <div className="pointframe20">
                                        <div className="pointframe-child14" />
                                    </div>
                                    <div className="highly-scalable-audio31">
                                        We look forward to receiving your feedback
                                    </div>
                                </div>
                            </div>
                            <div className="fields3">
                                <div className="emailphone1">
                                    <Input
                                        className="business-email-id7"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => handleInputChange(e, "name")}
                                        maxLength={100}
                                        onKeyDown={handleKeyPress}
                                    />
                                    <Input
                                        className="business-email-id8"
                                        placeholder="Organization"
                                        value={organization}
                                        onChange={(e) => handleInputChange(e, "organization")}
                                        maxLength={100}
                                        onKeyDown={handleKeyPress}
                                    />
                                    <Input
                                        className="business-email-id8"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => handleInputChange(e, "email")}
                                        maxLength={100}
                                    />
                                    {error && <div className="error-text">{error}</div>}
                                    <textarea
                                        className="business-email-id10 comments-textarea"
                                        placeholder="Comments"
                                        value={comments}
                                        maxLength={500}
                                        onChange={(e) => handleCommentsChange(e, "comments")}
                                    />
                                </div>
                                <div className="buttons1 margin-top">
                                    <button className="buttonreset1 cursor-pointer" onClick={onSkipClick}>
                                        <b className="smart-jackpots-that4">SKIP</b>
                                    </button>
                                    <button className="buttonsend1 cursor-pointer" onClick={onSubmitClick}
                                        disabled={(name && organization && email && comments) ? false : true}
                                        ref={feedbackButtonRef}
                                    >
                                        <div className="smart-jackpots-that-you-may-lo-container">
                                            <b className="smart-jackpots-that5">Submit</b>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <FeatureCards />
            <Footer />
        </div>
    );
};

export default FeedbackForm;