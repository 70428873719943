import React, { useEffect, useRef, useState } from "react";

//Components
import HeaderNavigation from "../CommonComponents/HeaderNavigation/HeaderNavigation";
import FeatureCards from "../KeyFeatures/KeyFeatures";
import Footer from "../CommonComponents/Footer/Footer";

//Action
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";

//Assets
import HeroImage from "../../Resources/Images/sales-images/imagebannercommon@2x.webp";
import VideoIcon from "../../Resources/Images/sales-images/union.svg";

//Utility
import { validateEmail, handleKeyPress, validatePhoneNumber, containsOnlySpaces } from "../../Utility/Utils";

//Styles
import "./RequestDemo.scss";
import "../CommonComponents/HeroSection/HeroSection.scss"

interface RequestDemoProps {
	onRequestDemoClick: (requestDemoForm: {}) => void;
	isApiSuccess: boolean
}

const RequestDemo: React.FC<RequestDemoProps> = ({ onRequestDemoClick, isApiSuccess }) => {
	const [requestDemoForm, setRequestDemoForm] = useState({ name: "", company_name: "", email: "", contact: "" });

	const requestButtonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		/* @ts-ignore */
		requestButtonRef.current.enabled = true
	}, [requestDemoForm]);

	useEffect(() => {
		isApiSuccess && setRequestDemoForm({ name: "", company_name: "", email: "", contact: "" });
	}, [isApiSuccess]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
		let value = e.target.value;
		setRequestDemoForm({ ...requestDemoForm, [type]: value });
	};

	const handleFormSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
		console.log('!validateEmail(requestDemoForm?.email): ', validateEmail(requestDemoForm?.email));

		if (validateEmail(requestDemoForm?.email) && !containsOnlySpaces(requestDemoForm?.name) && !containsOnlySpaces(requestDemoForm?.company_name)) {
			onRequestDemoClick(requestDemoForm);
		} else {
			setErrorNotification("Please enter valid data!")
		}
	};

	const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// Check if the pressed key is Enter (key code 13)
		if (event.key === 'Enter') {
			if (requestButtonRef.current && !requestButtonRef.current.disabled) {
				onRequestDemoClick(requestDemoForm);
			}
		}
	}

	return (
		<div className="proconflanding-intro">

			<HeaderNavigation />

			<div className="bannercomponentwrapper">
				<div className="bannercomponent">
					<div className="banner5">
						<div className="content19">
							<img
								className="imagebannercommon-icon4"
								alt=""
								src={HeroImage}
							/>
							<div className="introsection1">
								<div className="banner3title3">
									<div className="own-your-video3">{`Own Your Video Conferencing Platform `}</div>
									<div className="subtext117">
										<div className="pointframe17">
											<div className="pointframe-child14" />
										</div>
										<div className="highly-scalable-audio21">
											Highly scalable audio-video conferencing platform
										</div>
									</div>
									<div className="subtext117">
										<div className="pointframe17">
											<div className="pointframe-child14" />
										</div>
										<div className="highly-scalable-audio21">
											AI-enabled features like Meeting Summary and Catch-up
											Meeting
										</div>
									</div>
									<div className="subtext117">
										<div className="pointframe17">
											<div className="pointframe-child14" />
										</div>
										<div className="highly-scalable-audio21">
											AI-enabled feature like Auto-center Camera in the video window
										</div>
									</div>
								</div>
								<div className="nameorganization-group">
									<div className="nameorganization1">
										<input
											onChange={(e) => handleInputChange(e, "name")}
											className="name2"
											name="name"
											placeholder="Name"
											type="text"
											required
											maxLength={100}
											value={requestDemoForm?.name || ""}
											onKeyDown={handleKeyPress}
										/>
										<input
											onChange={(e) => handleInputChange(e, "company_name")}
											className="organisation1"
											name="company_name"
											placeholder="Organization"
											type="text"
											required
											maxLength={100}
											value={requestDemoForm?.company_name || ""}
											onKeyDown={handleKeyPress}
										/>
									</div>
									<div className="emailphone">
										<input
											onChange={(e) => handleInputChange(e, "email")}
											className={`name2`}
											placeholder="Business email ID"
											type="text"
											name="email"
											required
											maxLength={100}
											value={requestDemoForm?.email || ""}
										/>
										<input
											onChange={(e) => handleInputChange(e, "contact")}
											className={`name2`}
											placeholder="Phone"
											type="text"
											maxLength={16}
											name="contact"
											required
											value={requestDemoForm?.contact || ""}
											onKeyPress={handleEnterKeyPress}
											onKeyDown={validatePhoneNumber}
										/>
									</div>
								</div>
								<button className="btnrequestdemo1" onClick={handleFormSubmit} ref={requestButtonRef}
									disabled={((requestDemoForm?.name !== "") && (requestDemoForm?.contact !== "")
										&& (requestDemoForm?.company_name !== "") && (requestDemoForm?.email !== ""))
										? false : true}>
									<div className="innerframebtn3">
										<div className="meetingicon8">
											<img
												className="union-icon13"
												alt=""
												src={VideoIcon}
											/>
										</div>
										<b className="banner3request8">Schedule a Demo</b>
									</div>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
			<FeatureCards />
			<Footer />
		</div>
	);
};

export default RequestDemo;
