import { FunctionComponent, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { Participant } from "proconf-web-sdk";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Components
import GridFrameGrid from "../GridFrame/GridFrameGrid";
import TranscriptPanel from "../TranscriptPanel/TranscriptPanel";
import ParticipantsPanel from "../ParticipantPanel/ParticipantsPanel";
import VideoNameTag from "../VideoNameTag/VideoNameTag";

//Actions
import { onParticipantPanelToggle, onTranscriptToggle } from "../../Redux/Actions/ToolbarActions";

//Styles
import "./InCallBodyContainer.scss";

export type InCallBodyContainerType = {
	screenSharingParticipant: Participant
	setInvitePopup: Function;
};

const InCallBodyContainer: FunctionComponent<InCallBodyContainerType> = ({
	screenSharingParticipant,
	setInvitePopup,
}) => {

	const configurations = useSelector(
		(state: any) => state.LoginReducer?.configurations
	);
	const {
		transcriptOn,
		participantPanelOpen
	} = useSelector((state: any) => state.ToolbarReducer);

	const participants = useSelector(
		(state: any) => state.ParticipantReducer?.participants
	);

	const localParticipant = useSelector(
		(state: any) => state.ParticipantReducer?.localParticipant
	);
	const remoteParticipants = useSelector(
		(state: any) => state.ParticipantReducer?.participants
	).filter((p: any) => p?.sid !== localParticipant?.sid);

	const isScreenShared = useSelector(
		(state: any) => state.ProConfReducer?.isRemoteSSOn
	);


	return (
		<div className="body" id="in-call-body">
			{isScreenShared === true ? (
				<div className="gridframefocused">
					<div className="griditemcall4">

						<VideoNameTag
							username={screenSharingParticipant?.identity}
							showMicOn={false}
							showMicOff={false}
							isPresenting={true}
						/>

						<div
							id="screen-div"
							className={window.innerHeight > window.innerWidth ? "portrait-screen" : "landscape-screen"}
						>
							<video
								id="screen-video"
								className={window.innerHeight > window.innerWidth ? "portrait-screen" : "landscape-screen"}
								autoPlay
								playsInline
							></video>
						</div>
					</div>
					{/* Temporary Hide  */}
					{/*<div
								id="griditemcall-parent"
								className="griditemcall-parent"
								style={{ justifyContent: "center" }}
							>
								{participants?.map((participant: any) => {
									return (
										<div className="griditemcall46" key={participant?.sid}>
											<div className="nametag4">
												{participant?.audioTracks[0]?.isEnabled ? (
													<div className="micon4">
														<MicOn
															className="subtraction-37-icon4"
														/>
													</div>
												) : (
													<div className="micoff4">
														<MicOff
															className="vector-icon23"
														/>
													</div>
												)}
												<div className="name6" title={participant?.identity}>{participant?.identity} </div>
												<span>{participant?.sid === localParticipant?.sid && getTranslation("you")}</span>
											</div>
											<div className="videoframe4">
												{participant?.videoTracks[0]?.isEnabled ? (
													<video
														id={participant?.sid === localParticipant?.sid ? "local-video" : "remote-video-" + participant?.sid}
														// className="videoframe4"
														autoPlay
														playsInline
													></video>
												) : (
													<div className="avatar-container">
														<CustomAvatar
															name={participant?.identity}
															hideName={true}
															size="50"
														/>
													</div>
												)}
											</div>
										</div>
									);
								})}
							</div>*/}
				</div>
			) : (
				// Local participant who is sharing screen will see this
				// All participants will see this grid when no one is sharing screen and there are 3-4 participants
				<GridFrameGrid
					participants={participants}
					localParticipant={localParticipant}
					remoteParticipants={remoteParticipants}
				/>
			)}

			{transcriptOn && "transcript" in configurations && (
				<TranscriptPanel
					isTranscriptionOn={transcriptOn}
					onClose={onTranscriptToggle}
				/>
			)}

			{participantPanelOpen && (
				<ParticipantsPanel
					onClose={onParticipantPanelToggle}
					setInvitePopup={setInvitePopup}
				/>
			)}

		</div>
	);
};

export default InCallBodyContainer;
