import { RoomState } from "../StateTypes/StateTypes";
import { RoomActionTypes } from "../ActionTypes/RoomActionTypes"
import {
    SET_GUEST_USER_NAME,
    SET_ROOM_NAME,
    CLEAR_ROOM_REDUCER,
    SET_CREATE_MEETING,
    CALL_IN_PROGRESS,
    SET_ROOM_ID
} from "../ReduxConstants";

// Define the initial state for the room reducer
const initialState: RoomState = {
    userName: '',
    roomName: "",
    isCreatemeeting: true,
    callInProgress: false,
    roomId: ''
};

// Reducer function to handle state changes based on actions
const RoomReducer = (
    state = initialState,
    action: RoomActionTypes
): RoomState => {

    switch (action.type) {
        case SET_GUEST_USER_NAME:
            return { ...state, userName: action.payload };
        case SET_ROOM_NAME:
            return { ...state, roomName: action.payload };
        case CLEAR_ROOM_REDUCER:
            return { ...state, userName: '', roomName: '', isCreatemeeting: true };
        case SET_CREATE_MEETING:
            return { ...state, isCreatemeeting: action.payload};
        case CALL_IN_PROGRESS:
                return { ...state, callInProgress: action.payload };
        case SET_ROOM_ID:
                return {...state, roomId: action.payload};
        default:
            return state;
    }
};

export default RoomReducer;
