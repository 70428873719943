import axios from "axios";
import { store } from "../Redux/store";
import { URLS_WITH_TOKEN } from "./ApiUrls";

// Create a new instance of Axios
const instance = axios.create({
  baseURL: process.env.REACT_APP_SALES_URL, // Set the base URL for all requests
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
});

console.log('process.env.REACT_APP_SALES_URL: ', process.env.REACT_APP_SALES_URL);

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // You can modify the request config here
    // For example, you can add authentication headers if the API URL is a part of URLS_WITH_TOKEN
    if (URLS_WITH_TOKEN.includes(config.url as string)) {
      const token = store.getState()?.LoginReducer?.token;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    config.data = {
      // @ts-ignore
      ...config?.requestBody,
    };
    return config;
  },
  (error) => Promise.reject(error)
);
// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // You can modify the response data here
    // For example, you can handle error responses globally
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default instance;
