import { useState } from "react";

//Components
import RequestDemo from "./RequestDemo";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Action
import { requestADemoAction } from "../Redux/Actions/SalesAction";
import { setErrorNotification, setSuccessNotification } from "../../Redux/Actions/NotificationAction";

//Constants
import { API_STATUS } from "../../Constants/AppConstants";
import SuccessMsgPopup from "../../Components/JoinMeetingPopup/SuccessMsgPopup";

export default function RequestDemoContainer() {

    const [isLoading, setIsLoading] = useState(false);
    const [isApiSuccess, setIsApiSuccess] = useState(false);


    async function onRequestDemoClick(demoForm: {}) {
        setIsLoading(true)
        setIsApiSuccess(false)

        let response = await requestADemoAction(demoForm);
        console.log('response: ', response);

        if (response?.status === API_STATUS.SUCCESS) {
            setIsApiSuccess(true)

            // setSuccessNotification(getTranslation("requestDemoSuccess"))
        }
        else {
            setErrorNotification(getTranslation(response?.error?.message))
        }
        setIsLoading(false)

    }

    const closeSuceessMessage = () => {
        setIsApiSuccess(false)
    }

    return (
        <>
            {isLoading && <LoadingSpinner />}

            <RequestDemo
                onRequestDemoClick={(demoForm: {}) => onRequestDemoClick(demoForm)}
                isApiSuccess={isApiSuccess}
            />

            {isApiSuccess &&
                <PortalPopup
                    placement="Centered"
                    className="success-container"
                >
                    <SuccessMsgPopup onCloseClick={closeSuceessMessage} />
                </PortalPopup>}
        </>

    )
}