import { FunctionComponent } from "react";

//Components
// import TimeAndRecordIcon from "../../Common Components/TimeAndRecordIcon/TimeandRecordIcon";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Styles
import "./InCallHeader.scss";

export type InCallHeaderType = {
	meetingName: string;
	className?: string;
};

const InCallHeader: FunctionComponent<InCallHeaderType> = ({
	meetingName,
	className = "",
}) => {
	return (
		<div className={`headerincall1 ${className}`}>
			<div className="headerinner1">
				<div className="meetingnameandtime2">
					<b className="meetingname2">
						{meetingName || getTranslation("meeting")}
					</b>
					{/* <TimeAndRecordIcon /> */}
				</div>
				<div className="screensharewrapper1">
					<div className="stopsharing1">
						<div className="stopsharingtext1">
							<div className="your-screen-is1">Your screen is being shared</div>
							<b className="chrome-tab1">Chrome Tab</b>
						</div>
						<button className="buttonprimary1">
							<b className="buttonlabel1">Stop Sharing</b>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InCallHeader;
