import { FunctionComponent, useMemo, type CSSProperties } from "react";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import MicOff from "../../Resources/Images/IconMicOffSmall.svg";
import MicOn from "../../Resources/Images/IconMicOnSmall.svg";

//Styles
import "./VideoNameTag.scss";

export type VideoNameTagType = {
	username?: string;
	showMicOn?: boolean;
	showMicOff?: boolean;
	isLocalParticipant?: boolean
	isPresenting?: boolean
};

const VideoNameTag: FunctionComponent<VideoNameTagType> = ({
	username,
	showMicOn = false,
	showMicOff = true,
	isLocalParticipant = false,
	isPresenting = false
}) => {

	console.log('showMicOff: ', showMicOff, showMicOn);

	return (
		<div className={`nametag`} style={{
			backgroundColor: "#fff",
			opacity: "0.8",
		}}>
			{showMicOff && (
				<div className="micoff">
					<img
						className="mic-off-icon"
						alt=""
						src={MicOff}
					/>
				</div>
			)}
			{showMicOn && (
				<div className="micon">
					<img className="mic-icon" alt="" src={MicOn} />
				</div>
			)}
			<div className="name" title={username}>{username}</div>
			{isLocalParticipant && <span>{getTranslation("you")}</span>}
			{isPresenting && <div> {" (" + getTranslation("presenting") + ")"}</div>}
		</div>
	);
};

export default VideoNameTag;
