import { FunctionComponent, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect"

//Components
import ToolBarButton from "../ToolBarButton/ToolBarButton";
import PortalPopup from "../PortalPopup/PortalPopup";
import CallDeviceSelection from "../CallDeviceSelection/CallDeviceSelection";
import VideoDeviceSelection from "../VideoDeviceSelection/VideoDeviceSelection";

//Constants
import { ROUTES } from "../../Routes/RouteConstants";
import { CONFIGURATIONS } from "../../Constants/AppConstants";

//Translations
import { getTranslation } from "../../Resources/Localization/i18n";

//Actions
import {
    onChangeLayout,
    onEndCallButtonClick,
    onEndMeeting,
    onMicMuteUnmute,
    onMoreActionClick,
    onMoreActionMobileClick,
    onParticipantPanelToggle,
    onRecordClick,
    onScreenSharing,
    onSpeakerMuteUnmute,
    onTranscriptToggle,
    onVideoMuteUnmute,
    onleaveCall
} from "../../Redux/Actions/ToolbarActions"

//Assets
// import ArrowIcon from "../../Resources/Images/arrowicon.svg"

import MicOnIcon from "../../Resources/Images/MicOnIcon.svg"
import VideoOnIcon from "../../Resources/Images/VideoOnIcon.svg"
import ScreenShareOnIcon from "../../Resources/Images/ScreenShareOnIcon.svg"
import TranscriptOnIcon from "../../Resources/Images/TranscriptOnIcon.svg"
import ParticipantsOnIcon from "../../Resources/Images/ParticipantsOnIcon.svg"

import MicOffIcon from "../../Resources/Images/MicOffIcon.svg"
import VideoOffIcon from "../../Resources/Images/VideoOffIcon.svg"
// import ChatOffIcon from "../../Resources/Images/ChatOffIcon.svg"
import ParticipantOffIcon from "../../Resources/Images/ParticipantsOffIcon.svg"
import ScreenShareOffIcon from "../../Resources/Images/ScreenShareOffIcon.svg"
// import LayoutOffIcon from "../../Resources/Images/LayoutOffIcon.svg"
// import SpeakerOffIcon from "../../Resources/Images/SpeakerOffIcon.svg"
import MenuOffIcon from "../../Resources/Images/MenuOffIcon.svg"
import EndCallIcon from "../../Resources/Images/CallEndIcon.svg"
import TranscriptOff from "../../Resources/Images/TranscriptOffIcon.svg"

//styles
import "./ToolActionBar.scss";
import { store } from "../../Redux/store";
import { useNavigate } from "react-router-dom";

export type ToolActionBarType = {
    className?: string;
};

const ToolActionBar: FunctionComponent<ToolActionBarType> = ({
    className = "",
}) => {
    // State to manage which components are visible
    const { configurations } = useSelector((state: any) => state.LoginReducer);
    const isRemoteScreenSharingOn = store.getState()?.ProConfReducer?.isRemoteSSOn;
    const isModerator = useSelector((state: any) => state.ParticipantReducer.localParticipant?.isModerator);
    const isGuestFlow = store.getState()?.RoomReducer.userName ?? false;
    const navigate = useNavigate();

    // const selectDeviceIconRef = useRef<HTMLButtonElement>(null);
    // const [isCallDeviceSelectionPopupOpen, setCallDeviceSelectionPopupOpen] =
    //     useState(false);
    // const selectDeviceIcon1Ref = useRef<HTMLButtonElement>(null);
    // const [isVideoDeviceSelectionPopupOpen, setVideoDeviceSelectionPopupOpen] =
    //     useState(false);

    const { audioMute,
        videoMute,
        screenShareStarted,
        transcriptOn,
        participantPanelOpen,
    } = useSelector((state: any) => state.ToolbarReducer);

    // const openCallDeviceSelectionPopup = useCallback(() => {
    //     setCallDeviceSelectionPopupOpen(true);
    // }, []);

    // const closeCallDeviceSelectionPopup = useCallback(() => {
    //     setCallDeviceSelectionPopupOpen(false);
    // }, []);

    // const openVideoDeviceSelectionPopup = useCallback(() => {
    //     setVideoDeviceSelectionPopupOpen(true);
    // }, []);

    // const closeVideoDeviceSelectionPopup = useCallback(() => {
    //     setVideoDeviceSelectionPopupOpen(false);
    // }, []);

    const onEndToolIconClick = () => {
        if (isGuestFlow) {
            onleaveCall()
            navigate(ROUTES.FEEDBACK)
        } else if (!isModerator) {
            onleaveCall()
            navigate(ROUTES.FEEDBACK)
        } else {
            onEndCallButtonClick()
        }
    }

    return (
        <>
            <div className={`actionbar ${className}`}>
                <div className="actionbarinner">
                    <div className="actionsframe">
                        <div className="micbuttonwrapper">
                            <ToolBarButton
                                toolBarButtonBorder={audioMute ? "none" : "1px solid #2a8067"}
                                toolBarButtonBackgroundColor={audioMute ? "transparent" : "#c6ead9"}
                                toolBarButtonPosition="unset"
                                toolIcon={audioMute ? MicOffIcon : MicOnIcon}
                                toolIconLabel={getTranslation("mic")}
                                toolIconLabelColor="#121313"
                                onClick={() => onMicMuteUnmute(audioMute)}
                            />
                            {/* <button
                                className="selectdeviceicon"
                                ref={selectDeviceIconRef}
                                onClick={openCallDeviceSelectionPopup}
                            >
                                <img className="arrowicon" alt="" src={ArrowIcon} />
                            </button> */}
                        </div>
                        {CONFIGURATIONS.VIDEO_CALL in configurations &&
                            <div className="micbuttonwrapper">
                                <ToolBarButton
                                    toolBarButtonBorder={videoMute ? "none" : "1px solid #2a8067"}
                                    toolBarButtonBackgroundColor={videoMute ? "transparent" : "#c6ead9"}
                                    toolBarButtonPosition="unset"
                                    toolIcon={videoMute ? VideoOffIcon : VideoOnIcon}
                                    toolIconLabel={getTranslation("video")}
                                    toolIconLabelColor="#121313"
                                    onClick={() => onVideoMuteUnmute(videoMute)}
                                />
                                {/* <button
                                className="selectdeviceicon"
                                ref={selectDeviceIcon1Ref}
                                onClick={openVideoDeviceSelectionPopup}
                            >
                                <img className="arrowicon" alt="" src={ArrowIcon} />
                            </button> */}
                            </div>
                        }
                        {/* <div className="chatbuttonwrapper">
                            <ToolBarButton
                                toolBarButtonBorder="none"
                                toolBarButtonBackgroundColor="transparent"
                                toolBarButtonPosition="unset"
                                toolIcon={ChatOffIcon}
                                toolIconLabel="Chat"
                                toolIconLabelColor="#121313"
                            />
                        </div> */}
                        {CONFIGURATIONS.SCREEN_SHARING in configurations &&
                            <div className="chatbuttonwrapper">
                                <ToolBarButton
                                    toolBarButtonBorder={screenShareStarted ? "1px solid #2a8067" : "none"}
                                    toolBarButtonBackgroundColor={screenShareStarted ? "#c6ead9" : "transparent"}
                                    toolBarButtonPosition="unset"
                                    toolIcon={screenShareStarted ? ScreenShareOffIcon : ScreenShareOnIcon}
                                    toolIconLabel={getTranslation("shareScreen")}
                                    toolIconLabelColor="#121313"
                                    onClick={() => onScreenSharing(screenShareStarted)}
                                    toolButtonClass={!isMobile ?
                                        (screenShareStarted ?
                                            (isRemoteScreenSharingOn ? " disabled" : "") :
                                            (isRemoteScreenSharingOn ? " disabled" : "")
                                        ) : "hide-class"}
                                />
                            </div>
                        }
                        {CONFIGURATIONS.TRANSCRIPT in configurations &&

                            <div className="chatbuttonwrapper">
                                <ToolBarButton
                                    toolBarButtonBorder={transcriptOn ? "1px solid #2a8067" : "none"}
                                    toolBarButtonBackgroundColor={transcriptOn ? "#c6ead9" : "transparent"}
                                    toolBarButtonPosition="unset"
                                    toolIcon={transcriptOn ? TranscriptOff : TranscriptOnIcon}
                                    toolIconLabel={getTranslation("transcript")}
                                    toolIconLabelColor="#121313"
                                    onClick={onTranscriptToggle}
                                />
                            </div>
                        }
                        <div className="chatbuttonwrapper">
                            <ToolBarButton
                                toolBarButtonBorder={participantPanelOpen ? "1px solid #2a8067" : "none"}
                                toolBarButtonBackgroundColor={participantPanelOpen ? "#c6ead9" : "transparent"}
                                toolBarButtonPosition="unset"
                                toolIcon={participantPanelOpen ? ParticipantOffIcon : ParticipantsOnIcon}
                                toolIconLabel={getTranslation("participants")}
                                toolIconLabelColor="#121313"
                                onClick={onParticipantPanelToggle}
                            />
                        </div>

                        {/* <div className="layoutbuttonwrapper">
                            <ToolBarButton
                                toolBarButtonBorder="none"
                                toolBarButtonBackgroundColor="transparent"
                                toolBarButtonPosition="unset"
                                toolIcon={LayoutOffIcon}
                                toolIconLabel="Layout"
                                toolIconLabelColor="#121313"
                            />
                        </div>
                        <div className="speakerbuttonwrapper">
                            <ToolBarButton
                                toolBarButtonBorder="none"
                                toolBarButtonBackgroundColor="transparent"
                                toolBarButtonPosition="unset"
                                toolIcon={SpeakerOffIcon}
                                toolIconLabel="Speaker"
                                toolIconLabelColor="#121313"
                            />
                        </div> */}
                        {/* <div className="chatbuttonwrapper">
                            <ToolBarButton
                                toolBarButtonBorder="none"
                                toolBarButtonBackgroundColor="transparent"
                                toolBarButtonPosition="unset"
                                toolIcon={MenuOffIcon}
                                toolIconLabel="More"
                                toolIconLabelColor="#121313"
                            />
                        </div> */}
                        <div className="moretabmobilebuttonwrapper">
                            <ToolBarButton
                                toolBarButtonBorder="none"
                                toolBarButtonBackgroundColor="transparent"
                                toolBarButtonPosition="unset"
                                toolIcon={MenuOffIcon}
                                toolIconLabel="More"
                                toolIconLabelColor="#121313"
                                onClick={onMoreActionMobileClick}
                            />
                        </div>
                        <ToolBarButton
                            toolBarButtonBorder="none"
                            toolBarButtonBackgroundColor="#db3312"
                            toolBarButtonPosition="unset"
                            toolIcon={EndCallIcon}
                            toolIconLabel={getTranslation("endCall")}
                            toolIconLabelColor="#fff"
                            onClick={onEndToolIconClick}
                            isEndCallRef={true}
                        />
                    </div>
                </div>
            </div>
            {/* {isCallDeviceSelectionPopupOpen && (
                <PortalPopup
                    placement="Top right"
                    relativeLayerRef={selectDeviceIconRef}
                    onOutsideClick={closeCallDeviceSelectionPopup}
                >
                    <CallDeviceSelection onClose={closeCallDeviceSelectionPopup} />
                </PortalPopup>
            )}
            {isVideoDeviceSelectionPopupOpen && (
                <PortalPopup
                    placement="Top right"
                    relativeLayerRef={selectDeviceIcon1Ref}
                    onOutsideClick={closeVideoDeviceSelectionPopup}
                >
                    <VideoDeviceSelection onClose={closeVideoDeviceSelectionPopup} />
                </PortalPopup>
            )} */}
        </>
    );
};

export default ToolActionBar;
